import React, { lazy, useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from './components/Router';
import { HomeScreen } from './HomeScreen';
import { SiteLoadingContextProvider } from './SiteLoadingIndicator';
import { ErrorBoundary } from './ErrorBoundary';

const OnboardingPromise = import(/* webpackPrefetch: true */ './Onboarding');

const Onboarding = lazy(() => OnboardingPromise);

const AppPromise = import(/* webpackPrefetch: true */ './App');

const App = lazy(() => AppPromise);

const ThankYouScreen = lazy(() => import('./ThankYouScreen'));

const RootRouter = () => {
   useEffect(() => {
      document.getElementById('site-scripts-loading').classList.add('loaded');
   }, []);

   return (
      <BrowserRouter>
         <SiteLoadingContextProvider>
            <HelmetProvider>
               <ErrorBoundary>
                  <Router>
                     <Route path="/" element={<HomeScreen />} />

                     {/* <Route path="/getting-started" element={<Onboarding />} />

                     <Route path="/thank-you" element={<ThankYouScreen />} /> */}

                     <Route path="/app/*" element={<App />} />
                  </Router>
               </ErrorBoundary>
            </HelmetProvider>
         </SiteLoadingContextProvider>
      </BrowserRouter>
   );
};

export { RootRouter };
