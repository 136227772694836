import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ShowSiteLoading } from '../SiteLoadingIndicator';
import { Title } from './Title';

const NotFound = () => (
   <>
      <Title title="Not Found" />
      <div>Not Found</div>
   </>
);

const Router = ({ children }) => (
   <Suspense fallback={<ShowSiteLoading />}>
      <Routes>
         {children}
         <Route path="*" element={<NotFound />} />
      </Routes>
   </Suspense>
);

export { Router };
