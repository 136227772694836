import React from 'react';

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError(error) {
      const type = error.message.match(/^Loading/) ? 'Network' : 'Unexpected';
      return { hasError: true, type };
   }

   render() {
      const { hasError, type } = this.state;
      const { children } = this.props;

      if (!hasError) {
         return children;
      }
      if (type === 'Network') {
         return <p>Network Error</p>;
      }
      return <p>Unexpected Error</p>;
   }
}

export { ErrorBoundary };
