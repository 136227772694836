import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { gaEnv } from '../util/constants';
import ReactGA from 'react-ga4';

const Title = ({
   title,
   subtitle,
   excludeBranding,
   description = 'Learn mixing, mastering, sound design, and more! Ear Instructor offers ear training exercises and interactive tutorials for producers and audio engineers.',
}) => {
   const fullTitle = useMemo(() => {
      const fragments = [];

      if (title) {
         fragments.push(title);
      }
      if (subtitle) {
         fragments.push(subtitle);
      }

      const fragments2 = [fragments.join(' - ')];

      if (!excludeBranding) {
         fragments2.push('Ear Instructor');
      }

      return fragments2.join(' | ');
   }, [title, subtitle, excludeBranding]);

   useEffect(() => {
      document
         .getElementById('site-meta-description')
         .setAttribute('content', description);
   }, [description]);

   useEffect(() => {
      if (gaEnv.enabled) {
         ReactGA.send({
            hitType: 'pageview',
            title: fullTitle,
            page: window.location.pathname + window.location.search,
         });
      }
   }, [fullTitle]);

   return (
      <Helmet>
         <title>{fullTitle}</title>
      </Helmet>
   );
};

export { Title };
