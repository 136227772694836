const VisitInfo = () => {
   const initialPath =
      JSON.parse(localStorage.getItem('initialPath'))?.path ||
      window.location.pathname;

   localStorage.setItem('initialPath', JSON.stringify({ path: initialPath }));

   const aid = new URLSearchParams(window.location.search).get('aid');
   const ref = new URLSearchParams(window.location.search).get('ref');

   const currentAffiliate = JSON.parse(localStorage.getItem('affiliate'));

   if (
      aid &&
      (currentAffiliate == null ||
         Date.now() > currentAffiliate.date + 28 * 24 * 60 * 60 * 1000)
   ) {
      localStorage.setItem(
         'affiliate',
         JSON.stringify({
            id: aid,
            date: Date.now(),
         })
      );
   }

   if (ref) {
      sessionStorage.setItem('referredBy', JSON.stringify(ref));
   }

   return null;
};

export { VisitInfo };
