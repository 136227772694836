export const sequencerInstrumentControlTypes = {
   stepSequencer: 'stepSequencer',
   pianoRoll: 'pianoRoll',
};

export const sequencerViewTypes = {
   stepSequencer: 'stepSequencer',
   pianoRoll: 'pianoRoll',
   mixer: 'mixer',
   mixerEffect: 'mixerEffect',
};

export const mixerEffectTypes = {
   eq: 'eq',
   width: 'width',
};

export const filterTypes = {
   lowpass: 'lowpass',
   highpass: 'highpass',
   bandpass: 'bandpass',
   lowshelf: 'lowshelf',
   highshelf: 'highshelf',
   peaking: 'peaking',
   notch: 'notch',
   allpass: 'allpass',
};

export const filterParameters = {
   type: 'type',
   detune: 'detune',
   frequency: 'frequency',
   Q: 'Q',
   gain: 'gain',
};

export const crossFadeOutputTypes = {
   reference: 'reference',
   user: 'user',
};
