/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HorizontalLogo } from './branding/HorizontalLogo';
import { getNoteName } from './util/getNoteName';
import { Title } from './components/Title';
import { FiCheckCircle, FiCircle } from 'react-icons/fi';

const getToFromExperience = (experience) => {
   switch (experience) {
      case '1': {
         return (
            // basic rhythm
            '/app/exercise/loop-legend?session-count=2&next=' +
            encodeURIComponent(
               '/app/exercise/chord-captain?session-count=2&next=' +
                  encodeURIComponent(
                     '/app/exercise/loop-legend?level=12&tutorial'
                  )
            )
            // add rythm in the piano roll
            // add extremely simple melody in piano roll
         );
      }
      case '2': {
         return '/app/exercise/loop-legend/melody';
      }
      case '3': {
         return '/app/course/finish-your-damn-songs';
      }
      case '4': {
         return (
            '/app/exercise/gain-guru/?session-count=2&next=' +
            encodeURIComponent(
               '/app/exercise/panning-pro?session-count=2&next=' +
                  encodeURIComponent('/app/exercise/eq-expert')
            )
         );
      }
   }
};

const Radio = ({ label, checked, onChange, id }) => (
   <div className="landing-survey-radio">
      <label htmlFor={id}>
         <input id={id} type="radio" checked={checked} onChange={onChange} />
         <span className="landing-radio">
            <FiCircle strokeWidth="3" />
            <FiCheckCircle strokeWidth="3" />
         </span>
         <span>{label}</span>
      </label>
   </div>
);

const HomeScreen = () => {
   const [noteArray] = useState(new Array(25).fill(null));

   const [selectedOption, setSelectedOption] = useState(null);

   const navigate = useNavigate();

   const handleSubmit = (e) => {
      e.preventDefault();

      navigate(getToFromExperience(selectedOption));
   };

   return (
      <div className="landing-page">
         <Title
            title="Ear Instructor"
            subtitle="Ear Training for Audio Engineers and Producers"
            excludeBranding
         />
         <div className="landing-page-logo">
            <HorizontalLogo />
         </div>
         <div className="landing-page-wrapper">
            <form className="landing-page-content" onSubmit={handleSubmit}>
               <h1 className="landing-page-main-heading">
                  From Your Head <span>To Your Speakers</span>
               </h1>
               {/* <div className="p">
                  <div
                     style={{
                        fontSize: '1.25em',
                        fontWeight: 'bold',
                        marginBottom: '1em',
                     }}
                  >
                     Which of the following best describes you?
                  </div>
                  <Radio
                     label="I'm a complete beginner"
                     checked={selectedOption === '1'}
                     onChange={() => setSelectedOption('1')}
                  />
                  <Radio
                     label="I have some experience but want to improve my melodies, chord progressions, etc."
                     checked={selectedOption === '2'}
                     onChange={() => setSelectedOption('2')}
                  />
                  <Radio
                     label="I'm pretty happy with my chords and melodies but can't turn them into full songs"
                     checked={selectedOption === '3'}
                     onChange={() => setSelectedOption('3')}
                  />
                  <Radio
                     label="I'm finishing songs and want to level up my mixing skills to improve the sound quality"
                     checked={selectedOption === '4'}
                     onChange={() => setSelectedOption('4')}
                  />
               </div>
               <button
                  type="submit"
                  className="landing-page-button"
                  disabled={selectedOption == null}
                  style={
                     selectedOption == null
                        ? { opacity: 0.5, pointerEvents: 'none' }
                        : undefined
                  }
               >
                  Practice Now!
               </button> */}

               <div className="p">
                  <div
                     style={{
                        fontSize: '1.25em',
                        fontWeight: 'bold',
                        marginBottom: '1em',
                     }}
                  >
                     Interactive tutorials and practice exercises coming soon!
                  </div>
               </div>
               <a
                  href="https://www.youtube.com/@IVTheFourth?sub_confirmation=1"
                  className="landing-page-button"
               >
                  Notify Me!
               </a>

               <div className="landing-page-piano-overlay" />
            </form>
            <div className="landing-page-piano">
               {noteArray.map((val, i) => {
                  const note = i - 22;
                  const noteName = getNoteName(note);
                  return <div key={note} data-note-name={noteName} />;
               })}
            </div>
         </div>
      </div>
   );
};

export { HomeScreen };
