import React, {
   useContext,
   createContext,
   useState,
   useEffect,
   useMemo,
} from 'react';
import { RoundLogo } from './branding/RoundLogo';

const SiteLoadingIndicator = ({ show }) => {
   const [visible, setVisible] = useState(false);
   const [hidden, setHidden] = useState(true);

   useEffect(() => {
      const timer = setTimeout(() => {
         if (show) {
            setVisible(true);
         }
      }, 500);

      return () => clearTimeout(timer);
   }, [show]);

   useEffect(() => {
      if (show) {
         setHidden(false);
      }
   }, [show]);

   useEffect(() => {
      setVisible(false);
   }, [hidden]);

   if (hidden) {
      return null;
   }

   return (
      <div
         className={`site-loading${show ? '' : ' loaded'}`}
         onTransitionEnd={(e) => {
            if (!show && e.target === e.currentTarget) {
               setHidden(true);
            }
         }}
      >
         <div className={`site-loading-indicator${visible ? ' visible' : ''}`}>
            <RoundLogo loader />
         </div>
      </div>
   );
};

const SiteLoadingContext = createContext();

const SiteLoadingContextProvider = ({ children }) => {
   const [isLoading, setIsLoading] = useState(false);

   const providerValue = useMemo(() => ({ setIsLoading }), []);

   return (
      <SiteLoadingContext.Provider value={providerValue}>
         {children}
         <SiteLoadingIndicator show={isLoading} />
      </SiteLoadingContext.Provider>
   );
};

const ShowSiteLoading = () => {
   const { setIsLoading } = useContext(SiteLoadingContext);

   useEffect(() => {
      setIsLoading(true);
      return () => {
         setIsLoading(false);
      };
   }, []);

   return null;
};

export { ShowSiteLoading, SiteLoadingContextProvider };
